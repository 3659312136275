.collage-wrapper-title {
  margin-top: 0;
  margin-bottom: 3rem;
}

.collage {
  display: grid;
}

.collage__item > * {
  width: 100%;
}

@media screen and (max-width: 749px) {
  .collage {
    grid-column-gap: var(--grid-mobile-horizontal-spacing);
    grid-row-gap: var(--grid-mobile-vertical-spacing);
  }

  .collage--mobile {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .collage--mobile .collage__item--left:nth-child(3n - 2) {
    grid-column: span 2;
  }

  .collage--mobile .collage__item--left:nth-child(3n - 2):nth-last-child(2) {
    grid-column: span 1;
  }

  .collage--mobile .collage__item--left:nth-child(3n) {
    grid-column-start: 2;
  }

  .collage--mobile .collage__item--right:nth-child(3n - 2) {
    grid-column-start: 1;
  }

  .collage--mobile .collage__item--right:nth-child(3n - 2):last-child {
    grid-column: span 2;
  }

  .collage--mobile .collage__item--right:nth-child(3n - 1) {
    grid-column-start: 2;
  }

  .collage--mobile .collage__item--right:nth-child(3n) {
    grid-column: 1 / span 2;
  }
}

@media screen and (min-width: 750px) {
  .collage {
    grid-auto-flow: column;
    grid-column-gap: var(--grid-desktop-horizontal-spacing);
    grid-row-gap: var(--grid-desktop-vertical-spacing);
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .collage__item--left:nth-child(3n - 2) {
    grid-column: 1 / span 2;
    grid-row: span 2;
  }

  .collage__item--left:nth-child(3n - 2):last-child {
    grid-column: 1 / span 3;
  }

  .collage__item--left:nth-child(3n - 1),
  .collage__item--left:nth-child(3n) {
    grid-column-start: 3;
  }

  .collage__item--left:nth-child(3n - 1):last-child {
    grid-row: span 2;
  }

  .collage__item--right:nth-child(3n - 2) {
    grid-column: 1 / span 1;
    grid-row: span 1;
  }

  .collage__item--right:nth-child(3n - 2):last-child {
    grid-column: 1 / span 3;
  }

  .collage__item--right:nth-child(3n - 1) {
    grid-column-start: 1;
  }

  .collage__item--right:nth-child(3n-1):last-child {
    grid-column: span 2;
  }

  .collage__item--right:nth-child(3n) {
    grid-column: 2 / span 2;
    grid-row: span 2;
  }

  .collage__item--collection:only-child,
  .collage__item--product:only-child {
    justify-self: center;
    max-width: 73rem;
    width: 100%;
  }
}

.collage-card {
  background: rgb(var(--color-background));
  border-radius: var(--card-corner-radius);
  border: var(--card-border-width) solid rgba(var(--color-foreground), var(--card-border-opacity));
  height: 100%;
  padding: var(--card-image-padding);
  position: relative;
}

.collage-card:after {
  border-radius: var(--card-corner-radius);
  box-shadow: var(--card-shadow-horizontal-offset) var(--card-shadow-vertical-offset) var(--card-shadow-blur-radius) rgba(var(--color-shadow), var(--card-shadow-opacity));
  content: '';
  position: absolute;
  width: calc(var(--card-border-width) * 2 + 100%);
  height: calc(var(--card-border-width) * 2 + 100%);
  top: calc(var(--card-border-width) * -1);
  left: calc(var(--card-border-width) * -1);
  z-index: -1;
}

.collage-card .media {
  border-radius: calc(var(--card-corner-radius) - var(--card-border-width) - var(--card-image-padding));
  height: 100%;
  overflow: hidden;
}

.collage-card .deferred-media {
  height: 100%;
  overflow: visible;
}

.collage-card__link {
  display: block;
  height: 100%;
}

.collage-card .deferred-media__poster {
  background-color: transparent;
  border: 0;
}

.collage-card .deferred-media__poster:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: calc(var(--card-border-width) * -1);
  left: calc(var(--card-border-width) * -1);
  right: calc(var(--card-border-width) * -1);
  top: calc(var(--card-border-width) * -1);
  outline-offset: 0.3rem;
}

.collage-card .deferred-media__poster:focus:after {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)), 0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3);
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
}

.collage-card .deferred-media__poster:focus-visible:after {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)), 0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3);
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
}

.collage-card .deferred-media__poster:focus:not(:focus-visible),
.collage-card .deferred-media__poster:focus:not(:focus-visible):after {
  outline: none;
  box-shadow: none;
}

.collage-card .deferred-media__poster:focus {
  outline: none;
  box-shadow: none;
}

.collage .collage-card-spacing img {
  object-fit: contain;
}
